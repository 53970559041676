'use client'

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { 
  Home, MessageCircle, Bell, Menu, User, LogOut, Search, 
  LayoutDashboard, Star, Briefcase, Plus, Shield, 
  Settings, Hammer, FileText, 
  Info, HelpCircle, Mail
} from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'
import { useAuth } from '@/context/AuthContext'
import { supabase } from '@/lib/supabase'
import UnreadMessagesCount from '@/components/notifications/unreadMessagesCount'
import MobileNavigation from './navigation/mobileNav'

const navbarClasses = "fixed left-0 top-20 h-[calc(100%-5rem)] bg-gray-900 z-40 overflow-y-auto overflow-x-hidden navbar hidden md:block border-r border-gray-800"



export default function Navbar() {
  const { user, signOut, loading } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [showServicesMenu, setShowServicesMenu] = useState(false)
  const [viewAsWorker, setViewAsWorker] = useState(false)
  const router = useRouter()
  const mobileMenuRef = useRef(null)
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [userRole, setUserRole] = useState(null)
  const servicesMenuRef = useRef(null)
  const closeTimeoutRef = useRef(null)

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const closeAll = () => {
    setShowServicesMenu(false)
    setIsHovered(false)
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current)
    }
  }


  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    const checkUserRole = async () => {
      if (!user) {
        setUserRole(null);
        return;
      }
      
      const { data: profile } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', user.id)
        .single();

      setUserRole(profile?.role);
    };

    checkUserRole();
  }, [user]);

  const navItems = useMemo(() => {
    const allItems = [
      { name: 'Acasă', href: '/', icon: Home, showFor: ['client', 'worker', null] },
      { name: 'Cere oferta', href: '/new-post', icon: Plus, showFor: ['client'] },
      { name: 'Servicii', href: '/servicii', icon: Search, showFor: ['client', 'worker', null], hasDropdown: true },
      { name: 'Lucrări Active', href: '/lucrari-actuale', icon: Briefcase, showFor: [null] },
      { name: 'Abonamente Pro', href: '/membership', icon: Star, showFor: ['worker'] },
      { name: 'Verifica Meseriasi', href: '/meseriasi', icon: Hammer, showFor: ['client', 'worker', null] },
      { name: 'Blog', href: '/blog', icon: FileText, showFor: ['client', 'worker', null] },
      { name: 'Intreaba Un Meserias', href: '/intrebari', icon: HelpCircle, showFor: ['client', 'worker', null] },
      { name: 'Despre Noi', href: '/despre-noi', icon: Info, showFor: ['client', 'worker', null] },
      { name: 'Contact', href: '/contact', icon: Mail, showFor: ['client', 'worker', null] },
    ];

    return allItems.filter(item => {
      // Pentru utilizatori neautentificați (userRole este null)
      if (!userRole) {
        return item.showFor.includes(null);
      }
      
      // Pentru utilizatori autentificați
      return item.showFor.includes(userRole);
    });
  }, [userRole]);

  const authItems = useMemo(() => {
    if (!user) return [];
    
    return [
      { 
        name: 'Panou de control', 
        icon: LayoutDashboard,
        onClick: () => {
          let dashboardRoute;
          if (user.user_metadata.role === 'client') {
            dashboardRoute = '/dashboard/client';
          } else if (user.user_metadata.role === 'worker') {
            dashboardRoute = '/dashboard/worker';
          } else if (user.user_metadata.role === 'admin') {
            dashboardRoute = '/dashboard/admin';
          }
          router.push(dashboardRoute);
        }
      },
      {
        name: 'Mesaje',
        icon: MessageCircle,
        onClick: () => router.push('/messages'),
        badge: unreadMessagesCount > 0 ? unreadMessagesCount : null
      },
      {
        name: 'Notificari',
        icon: Bell,
        onClick: () => router.push('/notifications'),
        badge: unreadCount > 0 ? unreadCount : null
      },
      
      { name: 'Setari', icon: Settings, onClick: () => router.push('/profil') },
      { name: 'Deconectare', icon: LogOut, onClick: async () => {
        try {
          const success = await signOut();
          if (success) {
            setUserRole(null);
            router.push('/');
          }
        } catch (error) {
          console.error('Error signing out:', error);
          window.location.href = '/';
        }
      }},
    ]
  }, [user, router, signOut, unreadCount, unreadMessagesCount])

  const renderNavItems = useCallback((isMobile = false) => (
    <div className={isMobile ? "mb-8" : "space-y-2"}>
      {navItems.map((item) => (
        <NavItem 
          key={item.name} 
          item={item} 
          isMobile={isMobile} 
          isHovered={isHovered}
          onClick={() => setIsOpen(false)} 
          onMouseEnter={() => item.hasDropdown && setShowServicesMenu(true)}
          onMouseLeave={() => item.hasDropdown && setShowServicesMenu(false)}
        />
      ))}
    </div>
  ), [navItems, setIsOpen, isHovered])

  const renderAuthItems = useCallback((isMobile = false) => {
    if (loading) {
      return (
        <div className="flex flex-col space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="w-9 h-9 bg-white bg-opacity-20 rounded-full"></div>
          ))}
        </div>
      )
    }

    const items = (
      <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
        {authItems && authItems.map((item, index) => (
          <AuthItem 
            key={index} 
            item={item} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
            isMobile={isMobile}
          />
        ))}
      </div>
    )

    if (!user) {
      return (
        <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
          {items}
          <AuthLink 
            href="/login" 
            icon={User} 
            text="Autentificare" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
          <AuthLink 
            href="/register/worker" 
            icon={Briefcase} 
            text="Înregistrare Meserias" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
        </div>
      )
    }

    return items
  }, [user, authItems, loading, setIsOpen, isHovered])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const checkUnreadNotifications = useCallback(async () => {
    if (!user) {
      console.log('No user found, skipping notifications check');
      return;
    }
    
    try {
      const { data: notificationsData, error: notificationsError } = await supabase
        .from('notifications')
        .select('id')
        .eq('user_id', user.id)
        .eq('is_read', false)
        .limit(100);

      if (notificationsError) {
        console.error('Supabase error:', notificationsError);
        return;
      }

      setUnreadCount(notificationsData?.length || 0);
    } catch (error) {
      console.error('Error checking notifications:', error);
      setUnreadCount(0);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      checkUnreadNotifications();

      const interval = setInterval(checkUnreadNotifications, 30000);

      const subscription = supabase
        .channel('notifications')
        .on('postgres_changes', {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .on('postgres_changes', {
          event: 'UPDATE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .on('postgres_changes', {
          event: 'DELETE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .subscribe();

      return () => {
        clearInterval(interval);
        subscription.unsubscribe();
      };
    }
  }, [user, checkUnreadNotifications]);

  return (
    <>
      {user && <UnreadMessagesCount setUnreadMessagesCount={setUnreadMessagesCount} />}
      <motion.nav 
        className={navbarClasses}
        initial={false}
        animate={{ width: isHovered ? '16rem' : '4.3rem' }}
        transition={{ duration: 0.15, ease: [0.23, 1, 0.32, 1] }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col h-full p-4 w-full">
          <div className="flex-grow space-y-1">
            {renderNavItems()}
          </div>
          <div className="mt-auto space-y-1">
            {renderAuthItems()}
          </div>
        </div>
      </motion.nav>

    

      <MobileNavigation 
        user={user}
        unreadMessagesCount={unreadMessagesCount}
        unreadCount={unreadCount}
        navItems={navItems}
        authItems={authItems}
        signOut={signOut}
      />
    </>
  )
}

const NavItem = React.memo(({ item, isMobile, onClick, isHovered, onMouseEnter, onMouseLeave }) => (
  <Link
    href={item.href}
    className={`group flex items-center text-gray-300 hover:text-white transition-colors duration-200 h-10 w-full relative ${
      item.hasDropdown ? 'hover:bg-gray-800 rounded-lg' : ''
    }`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className={`w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200 ${
      item.hasDropdown ? 'group-hover:bg-blue-500/20' : ''
    }`}>
      <item.icon className="h-5 w-5" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.15 }}
      >
        {item.name}
      </motion.span>
    )}
  </Link>
))

const AuthItem = React.memo(({ item, isHovered, onClick, isMobile }) => (
  <button
    className="group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full relative"
    onClick={() => {
      item.onClick();
      onClick();
    }}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 relative transition-colors duration-200">
      <item.icon className="h-6 w-6" />
      {item.badge && (
        <span className="absolute -top-1 -right-1 bg-gray-200 text-gray-900 text-xs rounded-full h-5 w-5 flex items-center justify-center font-medium">
          {item.badge}
        </span>
      )}
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {item.name}
      </motion.span>
    )}
  </button>
));

const AuthLink = React.memo(({ href, icon: Icon, text, onClick, isMobile, isHovered }) => (
  <Link 
    href={href} 
    className={`group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full`} 
    onClick={onClick}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200">
      <Icon className="h-6 w-6" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {text}
      </motion.span>
    )}
  </Link>
));
