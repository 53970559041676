'use client'

import { useRouter } from 'next/navigation'
import { UserPlus, CirclePlus, LogIn, Home, Bell, User, Search } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { useAuth } from '@/context/AuthContext'
import { useState, useEffect } from 'react'
import { supabase } from '@/lib/supabase'

export default function ActionNav() {
  const router = useRouter()
  const { user, signOut } = useAuth()
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (user) {
      const fetchUnreadNotifications = async () => {
        const { count, error } = await supabase
          .from('notifications')
          .select('*', { count: 'exact', head: true })
          .eq('user_id', user.id)
          .eq('is_read', false);

        if (!error) {
          setUnreadCount(count || 0);
        }
      };

      fetchUnreadNotifications();

      // Subscribe to changes
      const channel = supabase
        .channel('notifications')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public', 
            table: 'notifications',
            filter: `user_id=eq.${user.id}`
          }, 
          () => {
            fetchUnreadNotifications();
          }
        )
        .subscribe();

      return () => {
        channel.unsubscribe();
      };
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut()
      router.push('/')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <>
      {/* Mobile Navigation */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800/50 md:hidden">
        <div className="px-4 h-16 flex items-center justify-between">
          {/* Logo for Mobile */}
          <Link href="/" className="flex items-center gap-2 hover:opacity-90 transition-opacity">
            <Image
              src="/logo.svg"
              alt="Logo"
              width={140}
              height={140}
              className="w-auto h-8"
              priority
            />
            <span className="font-semibold text-base">
              <span className="text-white">Meserias</span>{' '}
              <span className="text-orange-500">Local</span>
            </span>
          </Link>

          {/* Action Button for Mobile */}
          <button
            onClick={() => router.push('/meseriasi')}
            className="flex items-center gap-1.5 py-2 px-3.5 
              bg-gradient-to-r from-orange-500 to-yellow-500
              hover:from-orange-600 hover:to-yellow-600
              text-white font-medium text-sm
              rounded-full shadow-lg shadow-orange-500/20
              transition-all duration-300 ease-out
              border border-orange-400/20
              active:scale-95 transform"
          >
            <Search className="w-4 h-4" />
            <span>Caută Meseriași</span>
          </button>
        </div>
      </div>

      {/* Desktop Navigation */}
      <div className="fixed top-0 left-0 right-0 z-50 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800/50 hidden md:block">
        <div className="max-w-7xl mx-auto px-4 h-20 flex items-center justify-between">
          {/* Logo and Text */}
          <Link href="/" className="flex items-center gap-3 hover:opacity-90 transition-opacity">
            <Image
              src="/logo.svg"
              alt="Logo"
              width={180}
              height={180}
              className="w-auto h-12"
              priority
            />
            <span className="font-semibold text-xl">
              <span className="text-white">Meserias</span>{' '}
              <span className="bg-gradient-to-r from-orange-500 to-yellow-500 text-transparent bg-clip-text">Local</span>
            </span>
          </Link>

          {/* Action Buttons */}
          <div className="flex items-center gap-4">
            {!user ? (
              <>
                <button
                  onClick={() => router.push('/meseriasi')}
                  className="group flex items-center gap-2 py-2.5 px-5
                    bg-gradient-to-r from-orange-500 to-yellow-500
                    hover:from-orange-600 hover:to-yellow-600
                    text-white font-medium
                    rounded-full shadow-lg shadow-orange-500/20
                    transition-all duration-300 ease-out
                    border border-orange-400/20
                    active:scale-95 transform"
                >
                  <Search className="w-5 h-5 group-hover:rotate-12 transition-transform" />
                  <span>Caută Meseriași</span>
                </button>

                <button
                  onClick={() => router.push('/register/worker')}
                  className="flex items-center gap-2 py-2.5 px-5
                    bg-gray-800 hover:bg-gray-700
                    text-white font-medium
                    rounded-full transition-all duration-200
                    border border-gray-700 hover:border-gray-600"
                >
                  <UserPlus className="w-5 h-5" />
                  <span>Devino Meseriaș</span>
                </button>

                <button
                  onClick={() => router.push('/new-post')}
                  className="flex items-center gap-2 py-2.5 px-5
                    bg-blue-600 hover:bg-blue-700
                    text-white font-medium
                    rounded-full transition-all duration-200
                    shadow-lg shadow-blue-500/20"
                >
                  <CirclePlus className="w-5 h-5" />
                  <span>Cere Oferte</span>
                </button>

                <button
                  onClick={() => router.push('/login')}
                  className="flex items-center gap-2 py-2.5 px-5
                    text-gray-300 hover:text-white
                    hover:bg-gray-800/50
                    rounded-full transition-all duration-200
                    font-medium"
                >
                  <LogIn className="w-5 h-5" />
                  <span>Autentificare</span>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => router.push(user.role === 'worker' ? '/dashboard/worker' : '/dashboard/client')}
                  className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-blue-700
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <Home className="w-5 h-5" />
                  <span>Dashboard</span>
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                {user.role === 'client' && (
                  <>
                    <button
                      onClick={() => router.push('/post-job')}
                      className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-orange-700
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <Briefcase className="w-5 h-5" />
                      <span>Postează Job</span>
                    </button>
                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}
                
                {user.role === 'worker' && (
                  <>
                    <button
                      onClick={() => router.push('/dashboard/jobs')}
                      className="flex items-center gap-2 py-2.5 px-4 text-white bg-orange-600 hover:bg-orange-700
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <Briefcase className="w-5 h-5" />
                      <span>Vezi Joburi</span>
                    </button>
                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}

                <button
                  onClick={() => router.push('/notifications')}
                  className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                    rounded-xl transition-all duration-200 font-medium relative"
                >
                  <Bell className="w-5 h-5" />
                  <span>Notificări</span>
                  {unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 w-5 h-5 bg-red-500 rounded-full text-xs flex items-center justify-center text-white">
                      {unreadCount}
                    </span>
                  )}
                </button>

                <div className="w-px h-8 bg-gray-700"></div>

                {user.role !== 'client' && (
                  <>
                    <button
                      onClick={() => router.push('/profil')}
                      className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                        rounded-xl transition-all duration-200 font-medium"
                    >
                      <User className="w-5 h-5" />
                      <span>Profil</span>
                    </button>

                    <div className="w-px h-8 bg-gray-700"></div>
                  </>
                )}

                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 py-2.5 px-4 text-gray-300 hover:text-white hover:bg-gray-800 
                    rounded-xl transition-all duration-200 font-medium"
                >
                  <LogIn className="w-5 h-5 rotate-180" />
                  <span>Deconectare</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
} 
